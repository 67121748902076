// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/buildhome/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-article-list-js": () => import("/opt/buildhome/repo/src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-post-js": () => import("/opt/buildhome/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-interview-js": () => import("/opt/buildhome/repo/src/templates/interview.js" /* webpackChunkName: "component---src-templates-interview-js" */),
  "component---src-templates-index-interview-js": () => import("/opt/buildhome/repo/src/templates/indexInterview.js" /* webpackChunkName: "component---src-templates-index-interview-js" */),
  "component---src-templates-draft-js": () => import("/opt/buildhome/repo/src/templates/draft.js" /* webpackChunkName: "component---src-templates-draft-js" */),
  "component---src-pages-404-js": () => import("/opt/buildhome/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

